import PropTypes from 'prop-types';

function StoreIcon({ className, size }) {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        // eslint-disable-next-line max-len
        d="M6.08174 5H25.9183C26.3197 5 26.6565 5.132 26.9285 5.396C27.2005 5.66 27.3361 5.98633 27.3351 6.375C27.3342 6.76367 27.1982 7.09046 26.9271 7.35537C26.656 7.62029 26.3197 7.75183 25.9183 7.75H6.08174C5.68029 7.75 5.34401 7.618 5.07292 7.354C4.80182 7.09 4.66579 6.76367 4.66485 6.375C4.66391 5.98633 4.79993 5.66 5.07292 5.396C5.3459 5.132 5.68218 5 6.08174 5ZM6.08174 27C5.68029 27 5.34401 26.868 5.07292 26.604C4.80182 26.34 4.66579 26.0137 4.66485 25.625V18.75H4.41689C3.96821 18.75 3.60218 18.5836 3.3188 18.2509C3.03542 17.9181 2.94096 17.5345 3.03542 17.1L4.45232 10.225C4.52316 9.90417 4.68846 9.64062 4.94823 9.43437C5.20799 9.22812 5.50318 9.125 5.83379 9.125H26.1662C26.4968 9.125 26.792 9.22812 27.0518 9.43437C27.3115 9.64062 27.4768 9.90417 27.5477 10.225L28.9646 17.1C29.059 17.5354 28.9646 17.919 28.6812 18.2509C28.3978 18.5827 28.0318 18.7491 27.5831 18.75H27.3351V25.625C27.3351 26.0146 27.1991 26.3414 26.9271 26.6054C26.655 26.8694 26.3188 27.0009 25.9183 27C25.5177 26.9991 25.1815 26.8671 24.9094 26.604C24.6374 26.3409 24.5014 26.0146 24.5014 25.625V18.75H18.8338V25.625C18.8338 26.0146 18.6978 26.3414 18.4257 26.6054C18.1537 26.8694 17.8174 27.0009 17.4169 27H6.08174ZM7.49864 24.25H16V18.75H7.49864V24.25ZM6.15259 16H25.8474L24.9973 11.875H7.00273L6.15259 16Z"
        fill="#0F4535"
      />
    </svg>
  );
}

export default StoreIcon;

StoreIcon.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
};

StoreIcon.defaultProps = {
  className: '',
  size: 32,
};
