/* eslint-disable react/button-has-type */
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { forwardRef } from 'react';

import { SpinIcon } from '../icons';

const Button = forwardRef((props, ref) => {
  const {
    id,
    className,
    children,
    type,
    color,
    size,
    startIcon,
    endIcon,
    isLoading,
    fullWidth,
    disabled,
    onClick,
    ...args
  } = props;

  const handleClick = (event) => {
    if (!isLoading) {
      onClick(event);
    }
  };

  return (
    <button
      id={id}
      ref={ref}
      className={
        clsx(
          'appearance-none rounded-lg disabled:cursor-not-allowed transition-colors',
          'disabled:bg-gray-200 disabled:text-gray-400 disabled:cursor-not-allowed',
          {
            'w-full': fullWidth,
            'px-2 py-1 text-sm': size === 'sm',
            'px-4 py-2 text-base': size === 'md',
            'px-6 py-4 text-lg': size === 'lg',
            'bg-gray-100 hover:bg-gray-300 active:bg-gray-500 text-baco-primary fill-baco-primary':
              color === 'primary-gray' && !disabled,
            'bg-baco-primary hover:bg-baco-primary-500 active:bg-baco-primary-700 text-white fill-white':
              color === 'primary' && !disabled,
            'bg-baco-secondary hover:bg-baco-secondary-500 active:bg-baco-secondary-700 text-baco-primary':
              color === 'secondary' && !disabled,
            'bg-red-200 hover:bg-red-400 active:bg-red-500 text-white fill-white':
              color === 'danger' && !disabled,
            'border border-gray-400 text-gray-700 fill-gray-700': color === 'outline' && !disabled,
            'border border-baco-primary border-2 text-baco-primary fill-white': color === 'outline-primary'
            && !disabled,
          },
          className,
        )
      }
      onClick={handleClick}
      disabled={disabled}
      type={type}
      {...args}
    >
      <span className="flex w-full space-x-3">
        {startIcon && (
          <span className="flex items-center">
            {startIcon}
          </span>
        )}
        <span
          className={
            clsx(
              'block w-full text-center',
              {
                'min-h-[20px] leading-5': size === 'sm',
                'min-h-[24px] leading-6': size === 'md',
                'min-h-[28px] leading-7': size === 'lg',
              },
            )
          }
        >
          {
            isLoading
              ? (
                <div className="w-full h-full flex items-center justify-center">
                  <SpinIcon
                    className={
                      clsx('animate-spin', {
                        'w-4 h-4': size === 'sm',
                        'w-5 h-5': size === 'md',
                        'w-6 h-6': size === 'lg',
                      })
                    }
                  />
                </div>
              )
              : children
          }
        </span>
        {endIcon && (
          <span className="flex items-center">
            {endIcon}
          </span>
        )}
      </span>
    </button>
  );
});

Button.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  type: PropTypes.oneOf(['submit', 'button']),
  color: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  id: '',
  children: null,
  className: '',
  type: 'button',
  color: 'primary',
  size: 'md',
  startIcon: null,
  endIcon: null,
  onClick: () => null,
  isLoading: false,
  fullWidth: false,
  disabled: false,
};

export default Button;
