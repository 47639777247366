import clsx from 'clsx';
import PropTypes from 'prop-types';
import Button from '../Button';

function ButtonGroup({
  options, onClick, value, selectedProps, className = '', selected, ...args
}) {
  return (
    <div className="flex header__category-wrapper">
      {options.map((option, index) => (
        <Button
          key={option.value}
          {...args}
          type="button"
          startIcon={option.icon}
          {...(option.value === value ? selectedProps : {})}
          className={clsx(className, 'button-group__item', {
            '!rounded-tr-none !rounded-br-none': index !== options.length - 1,
            '!rounded-tl-none !rounded-bl-none': index !== 0,
            [selectedProps?.className || '']: option.value === value,
            'button-group__item--active': option.value === value,
          })}
          onClick={(event) => onClick(event, option.value)}
        >
          {option.label}
        </Button>
      ))}
    </div>
  );
}

ButtonGroup.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  selectedProps: PropTypes.any,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.any,
      value: PropTypes.any,
      label: PropTypes.any,
    }),
  ),
  selected: PropTypes.bool,
};

ButtonGroup.defaultProps = {
  options: [],
  selectedProps: { color: 'primary' },
  className: '',
  value: undefined,
  onClick: () => null,
  selected: false,
};

export default ButtonGroup;
