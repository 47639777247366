import PropTypes from 'prop-types';
import Button from 'src/components/Button/index';
import formatCurrency from 'src/utils/currency';

function ContinueButton({
  form, title, total, totalWithDiscount, isLoading, handleBack,
}) {
  return (
    <div className="cart__overview__total font-semibold" data-is-back={handleBack ? 'true' : 'false'}>
      {handleBack ? (
        <Button
          type="button"
          onClick={handleBack}
          className="cart__overview__back border-baco-primary-600 border-2 !text-baco-primary"
          size="lg"
          fullWidth
          color="outline"
        >
          Seguir ordenando
        </Button>
      ) : null}
      <Button
        form={form}
        type="submit"
        isLoading={isLoading}
        className="cart__overview__total__button"
        size="lg"
        fullWidth
      >
        <span className="">{title}</span>
        {totalWithDiscount > 0 ? (
          <>
            <span className="line-through text-gray-200 mr-2">{formatCurrency(total)}</span>
            <span className="font-bold">{formatCurrency(totalWithDiscount)}</span>
          </>
        ) : (
          <span className="">{formatCurrency(total)}</span>
        )}
      </Button>
    </div>
  );
}

export default ContinueButton;

ContinueButton.propTypes = {
  form: PropTypes.string.isRequired,
  title: PropTypes.string,
  total: PropTypes.number,
  totalWithDiscount: PropTypes.number,
  isLoading: PropTypes.bool,
  handleBack: PropTypes.func,
};

ContinueButton.defaultProps = {
  title: 'Continuar',
  total: 0,
  totalWithDiscount: 0,
  isLoading: false,
  handleBack: null,
};
