import { useFormContext, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useEffect, useState } from 'react';
import useDelivery from 'src/hooks/actions/useDelivery';
import { toast } from 'react-toastify';
import InputContainer from '../InputContainer';
import DiscountInput from '.';
import { useCoupon } from '../../hooks/services/useCoupon';
import { CloseIcon } from '../icons/index';

function DiscountInputControlled(props) {
  const { control } = useFormContext();

  const {
    label, name, placeholder, helper, fullWidth, size, classes, disabled, direction, storeID, ...args
  } = props;
  const { deliveryData } = useDelivery();
  const type = deliveryData.orderType;
  const { isLoadingCoupon, coupon, validateCoupon } = useCoupon({ storeID, type });
  const [error, setError] = useState();

  return (
    <Controller
      control={control}
      name={name}
      render={({ fieldState, field: { onChange, onBlur, value } }) => {
        const mergedFieldState = {
          ...fieldState,
          invalid: error?.invalid || fieldState.invalid,
          error: error?.error || fieldState.error,
          isDirty: error?.isDirty || fieldState.isDirty,
        };

        useEffect(() => {
          onChange(coupon);
          setError(undefined);
        }, [coupon]);

        const handleChange = (code) => {
          setError(undefined);

          if (code) {
            validateCoupon(code, {
              onError: () => {
                const errorMessage = '¡Ups! Parece que este código no es válido.\nPor favor, '
                  + 'revísalo e intenta nuevamente.';

                setError({
                  error: { message: null },
                  invalid: true,
                  isDirty: true,
                });

                toast.error(
                  <div className="toast-content">
                    <CloseIcon className="toast-icon" />
                    <span>{errorMessage}</span>
                  </div>,
                  {
                    position: 'bottom-left',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    closeButton: true,
                    pauseOnHover: true,
                    className: 'custom-toast-error',
                    icon: false,
                    style: {
                      background: '#FDF4F3',
                      color: '#E00000',
                      borderRadius: '8px',
                      padding: '16px',
                      boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
                      fontSize: '14px',
                      fontWeight: '600',
                    },
                    draggable: true,
                  },
                );
              },
            });
          } else {
            onChange(undefined);
          }
        };

        return (
          <InputContainer
            label={label}
            helper={helper}
            direction={direction}
            className={clsx(classes)}
            fieldState={mergedFieldState}
          >
            <DiscountInput
              {...args}
              placeholder={placeholder}
              onBlur={onBlur}
              onChange={handleChange}
              value={value}
              size={size}
              disabled={disabled}
              fullWidth={fullWidth}
              fieldState={mergedFieldState}
              isLoading={isLoadingCoupon}
            />
          </InputContainer>
        );
      }}
    />
  );
}

DiscountInputControlled.propTypes = {
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  helper: PropTypes.node,
  size: PropTypes.string,
  classes: PropTypes.array,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  direction: PropTypes.oneOf(['column', 'row', 'row-reverse']),
  storeID: PropTypes.string,
};

DiscountInputControlled.defaultProps = {
  label: null,
  placeholder: '',
  helper: null,
  size: 'md',
  classes: [],
  disabled: false,
  fullWidth: false,
  direction: 'column',
  storeID: '',
};

export default DiscountInputControlled;
