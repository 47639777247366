import { useNavigate } from 'react-router-dom';
import LogoBacu from 'src/components/icons/Logo';

function LogoButton() {
  const navigate = useNavigate();
  return (
    <button
      type="button"
      onClick={() => navigate('/')}
      className="header__logo-button"
      title="Ir a la página principal"
      aria-label="Ir a la página principal"
    >
      <LogoBacu className="header__logo" />
    </button>
  );
}

export default LogoButton;
