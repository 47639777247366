import PropTypes from 'prop-types';
import clsx from 'clsx';
import Button from '../Button';
import { ChevronLeftIcon } from '../icons';
import BackButton from '../BackButton';

function HeaderWithButton({
  buttonVariant,
  centerAdornment,
  endAdornment,
  onClick,
  title,
  isTextColorPrimary,
  titleCenter,
}) {
  return (
    <div
      className={clsx('flex justify-between items-center w-full px-4 gap-x-4 pt-4 header__with__button', {
        '!pt-6': titleCenter,
      })}
    >
      {buttonVariant === 'outline' ? (
        <BackButton onClick={onClick} title="Volver a la tienda" />
      ) : (
        <Button color="unstyled" className="py-3 px-3" size="md" onClick={onClick}>
          <ChevronLeftIcon className="h-14 w-14 fill-baco-primary" />
        </Button>
      )}

      <div className="flex items-center flex-col w-full">
        {centerAdornment}

        {title && (
          <h2
            className={clsx('text-xl m-0 text-slate-900 leading-none text-center font-semibold cart__title', {
              '!text-baco-primary': !!isTextColorPrimary,
              'mr-16': titleCenter,
            })}
          >
            {title}
          </h2>
        )}
      </div>

      {endAdornment}
    </div>
  );
}

HeaderWithButton.propTypes = {
  buttonVariant: PropTypes.oneOf(['normal', 'outline']),
  centerAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  onClick: PropTypes.func,
  title: PropTypes.string,
  isTextColorPrimary: PropTypes.string,
  titleCenter: PropTypes.bool,
};

HeaderWithButton.defaultProps = {
  buttonVariant: 'outline',
  centerAdornment: null,
  endAdornment: null,
  onClick: () => null,
  title: '',
  isTextColorPrimary: '',
  titleCenter: false,
};

export default HeaderWithButton;
