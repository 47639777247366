import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import useDelivery from 'src/hooks/actions/useDelivery';
import { SpinIcon } from '../../../components/icons';
import { useLogin } from '../../../contexts/Login.context';
import { useUser } from '../../../hooks/actions/useUser';
import { addUserAddress } from '../../../services/api';

function UserInformation(props) {
  const { updateUser } = useUser();
  const { userInfo, onRequestToken } = useLogin();
  const timmerRef = useRef();
  const { deliveryData } = useDelivery();

  const { onSubmit, store, step } = props;

  useEffect(() => {
    timmerRef.current = setTimeout(() => {
      onRequestToken(`/store/${store.storeId}/cart?step=2`);
      const shouldAddUserAddress = deliveryData.isDelivery && deliveryData.isNewAddress;

      if (step === 2 && userInfo) {
        if (shouldAddUserAddress) {
          addUserAddress({
            user_id: parseInt(userInfo.merchant_customer_id, 10),
            alias: '',
            label: deliveryData.address,
            detail: deliveryData.addressDetail,
            lat: deliveryData.lat,
            lng: deliveryData.lng,
          });
        }
        updateUser({ phone: { ...userInfo.phone } });
        onSubmit(userInfo);
      }
    }, 800);

    return () => clearTimeout(timmerRef.current);
  }, [userInfo, step]);

  return (
    <div className="p-3 flex justify-center h-full cart__user-information">
      <div className="flex flex-col items-center justify-center">
        <SpinIcon className="animate-spin w-10 h-10 text-baco-primary" />
        <span className="my-3 text-gray-700">Cargando tus datos...</span>
      </div>
    </div>
  );
}

UserInformation.propTypes = {
  onSubmit: PropTypes.func,
  store: PropTypes.any,
  step: PropTypes.number.isRequired,
};

UserInformation.defaultProps = {
  onSubmit: () => null,
  store: {},
};

export default UserInformation;
