import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ButtonGroup from '../../components/ButtonGroup';
import CommonHeader from '../../components/CommonHeader';
import IconRender from '../../components/IconRender';

function Header({
  selectedCategoryType, categoryTypes, onChangeCategoryType, onSearch, isLoading, menu,
}) {
  const location = useSelector((state) => state.location);
  const currentLocation = location.selectedStore;
  const navigation = useNavigate();
  const { storeID: legacyID } = useParams();
  const handleShowStores = () => navigation('/stores');
  const handleSelectOrderMethod = () => navigation('/SelectOrderMethod');
  const handleCart = () => navigation(`/store/${legacyID}/cart`);

  return (
    <CommonHeader
      onGoToCart={handleCart}
      onGoToSelectOrderMethod={handleSelectOrderMethod}
      onGoToStores={handleShowStores}
      currentLocation={currentLocation}
      isLoading={isLoading}
      menu={menu}
      onSearch={onSearch}
      isStore
      endComponent={
        categoryTypes.length > 1 && (
          <ButtonGroup
            color="primary-gray"
            className="header__category-type"
            size="sm"
            selectedProps={{
              color: 'primary',
            }}
            value={selectedCategoryType}
            onClick={(_, value) => {
              onChangeCategoryType(value);
            }}
            options={categoryTypes.map(({ categoryType, icon }) => ({
              value: categoryType,
              label: categoryType,
              icon: <IconRender icon={icon} className="w-3 h-3" />,
              selected: categoryType === selectedCategoryType,
            }))}
          />
        )
      }
    />
  );
}

Header.propTypes = {
  selectedCategoryType: PropTypes.string,
  categoryTypes: PropTypes.array,
  onChangeCategoryType: PropTypes.func,
  onSearch: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
};

Header.defaultProps = {
  selectedCategoryType: undefined,
  categoryTypes: [],
  onChangeCategoryType: () => null,
  onSearch: () => null,
};

export default Header;
