import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useSearchParams } from 'react-router-dom';
import Search from '../icons/Search';

function SearchBar({ placeholder, onSearch }) {
  const inputRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const isMacOS = () => /Mac|iPod|iPhone|iPad/.test(navigator.userAgent);

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const handleSearch = useDebouncedCallback((value) => {
    const currentParams = Object.fromEntries(searchParams.entries());

    if (value === '') {
      const { q, ...restParams } = currentParams;
      setSearchParams(restParams);
    } else {
      setSearchParams({
        ...currentParams,
        q: value,
      });
    }

    if (onSearch) onSearch(value);
  }, 500);

  useEffect(() => {
    const handleKeyDown = (e) => {
      const isCmd = isMacOS() ? e.metaKey : e.ctrlKey;

      if (isCmd && e.key.toLowerCase() === 'k') {
        e.preventDefault();
        if (inputRef.current) inputRef.current.focus();
      }

      if (e.key === 'Escape' && document.activeElement === inputRef.current) {
        e.preventDefault();
        if (inputRef.current) {
          inputRef.current.blur();
          inputRef.current.value = '';
          handleSearch('');
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [handleSearch]);

  return (
    <form className="form container header__search-bar" onSubmit={handleSubmit}>
      <Search className="icon__search" />
      <input
        ref={inputRef}
        required
        autoComplete="off"
        className="input"
        defaultValue={searchParams.get('q') || ''}
        enterKeyHint="search"
        inputMode="text"
        maxLength={17}
        name="q"
        placeholder={placeholder}
        type="search"
        onChange={(e) => handleSearch(e.target.value)}
      />
    </form>
  );
}

SearchBar.defaultProps = {
  placeholder: 'Buscar...',
  onSearch: () => {},
};

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  onSearch: PropTypes.func,
};

export default SearchBar;
