import { useEffect } from 'react';
import { useLocation, useNavigationType } from 'react-router-dom';

const useScrollHandler = () => {
  const { pathname, state } = useLocation();
  const navigationType = useNavigationType();

  const scrollToPosition = (position) => {
    window.scrollTo({
      top: position,
      behavior: 'instant',
    });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  };

  const savePosition = () => {
    sessionStorage.setItem(`scroll-${pathname}`, window.scrollY.toString());
  };

  const getPosition = () => parseInt(sessionStorage.getItem(`scroll-${pathname}`), 10) || 0;

  useEffect(() => {
    const paths = pathname.split('/');
    const isProduct = paths.includes('product');
    const isStore = paths.includes('store');
    const isCart = paths.includes('cart');
    const isBackNavigation = state?.isBack;

    if (isCart) {
      return;
    }

    if (isProduct) {
      scrollToTop();

      return;
    }

    if (isStore && isBackNavigation) {
      const savedPosition = state?.scrollPosition || getPosition();
      if (savedPosition) {
        scrollToPosition(savedPosition);
      }

      return;
    }

    if (navigationType === 'PUSH') {
      savePosition();
      scrollToTop();
    }

    if (navigationType === 'POP') {
      const savedPosition = getPosition();
      scrollToPosition(savedPosition);
    }
  }, [pathname, navigationType, state]);
};

export default useScrollHandler;
