import PropTypes from 'prop-types';
import {
  // eslint-disable-next-line comma-dangle
  createContext, useContext, useEffect, useMemo, useState
} from 'react';
import { useParams } from 'react-router-dom';
import mapLegacyStore from 'src/helpers/migrationRedirect';
import useDelivery from 'src/hooks/actions/useDelivery';
import Button from '../components/Button';
import Drawer from '../components/Drawer';
import { InfoIcon, ScheduleIcon } from '../components/icons';
import config from '../config';
import { useShoppingCart } from '../hooks/actions/useShoppingCart';
import { useStore } from '../hooks/actions/useStore';
import { useConfigCategories } from '../hooks/services/useConfigCategories';
import { useSessionStorage } from '../hooks/useSessionStorage';
import { getCurrentDayMinutes, getMinutesOfDay } from '../utils/date';
import { useAlert } from './Alert.context';

const DEFAULT_SCHEDULER = {
  fastSchedule: {
    from: '00:00',
    to: '23:59',
  },
  maxSchedule: '',
};

const MIN_MS = 60000;

const Context = createContext({});

function roundDate(unixDate) {
  const interval = config.minutesInterval * MIN_MS;

  return Math.round(unixDate / interval) * interval;
}

function storeCloseTime(store) {
  const now = new Date();
  const h = store?.currentSchedule?.closeHour || 0;
  const m = store?.currentSchedule?.closeMinute || 0;

  return new Date(now.getFullYear(), now.getMonth(), now.getDate(), h, m, 0, 0);
}

function SchedulerContext(props) {
  const { children } = props;

  const { storeID: legacyID } = useParams();
  const storeID = mapLegacyStore(legacyID);
  const { selectedStore } = useStore();
  const { isLoadingConfig, availableCategories } = useConfigCategories({ storeID });
  const { changeScheduler, scheduler } = useShoppingCart();
  const [sessionCategoryType, setSessionCategoryType] = useSessionStorage('sessionCategory', '');
  const [acceptScheduleDisclaimer, setAcceptScheduleDisclaimer] = useSessionStorage('scheduleDisclaimer', '');
  const [selectedCategoryType, setCategoryType] = useState(sessionCategoryType);
  const [openCategoryDrawer, setOpenCategoryDrawer] = useState();
  const [notAvailableSchedule, setNotAvailableSchedule] = useState(false);
  const { showAlert, closeModal } = useAlert();
  const { deliveryData } = useDelivery();

  const handleAcceptScheduleDisclaimer = () => setAcceptScheduleDisclaimer('accepted');
  const handleCategoryType = (item) => {
    const categoryType = typeof item === 'string' ? item : item.categoryType;
    const findCategory = (cat) => cat.categoryType === item;
    const categoryConfig = typeof item === 'string' ? availableCategories.find(findCategory) : item;

    setAcceptScheduleDisclaimer('');
    setSessionCategoryType(categoryType);
    setCategoryType(categoryType);
    changeScheduler(categoryConfig);
    setOpenCategoryDrawer(false);
  };

  const availableCategoriesStr = JSON.stringify(availableCategories);

  const timers = useMemo(() => {
    if (!scheduler) {
      return {
        isLoading: true,
        minSchedule: 0,
        maxSchedule: 0,
        suggestedFrom: new Date(),
        suggestedTo: new Date(),
        timeToClose: 0,
      };
    }
    const curr = new Date();
    const closeTime = storeCloseTime(selectedStore);

    const {
      fastSchedule = DEFAULT_SCHEDULER.fastSchedule,
      maxSchedule: maxSch = DEFAULT_SCHEDULER.maxSchedule,
    } = scheduler;

    const { from = DEFAULT_SCHEDULER.fastSchedule.from, to = DEFAULT_SCHEDULER.fastSchedule.to } = fastSchedule;

    const [hoursFrom = 0, minutesFrom = 0] = (from || '').split(':');
    const [hoursTo = 0, minutesTo = 0] = (to || '').split(':');
    const [hoursMax = 0, minutesMax = -1] = (maxSch || '').split(':');
    const suggestedFrom = new Date(curr.getFullYear(), curr.getMonth(), curr.getDate(), hoursFrom, minutesFrom, 0, 0);
    const suggestedTo = minutesMax >= 0
      ? new Date(curr.getFullYear(), curr.getMonth(), curr.getDate(), hoursMax, minutesMax, 0, 0)
      : new Date(curr.getFullYear(), curr.getMonth(), curr.getDate(), hoursTo, minutesTo, 0, 0);
    const minSchedule = roundDate(Math.max(+suggestedFrom, +new Date() + config.scheduleTimeMin));
    const maxSchedule = roundDate(
      minutesMax > 0 ? +suggestedTo : Math.min(+suggestedTo, +closeTime - config.scheduleBeforeClose),
    );
    const timeToClose = Math.max(maxSchedule - +curr, 0);

    return {
      isLoading: !selectedStore?.currentSchedule,
      minSchedule,
      maxSchedule,
      suggestedFrom,
      suggestedTo,
      timeToClose,
    };
  }, [scheduler, selectedStore]);

  useEffect(() => {
    const { timeToClose, isLoading } = timers;
    let timmerClose;

    if (!isLoading && availableCategories.length > 0 && selectedCategoryType && !deliveryData.isDelivery) {
      if (timeToClose > 0) {
        closeModal();
      }
    }

    return () => clearTimeout(timmerClose);
  }, [timers, availableCategoriesStr, selectedCategoryType]);

  useEffect(() => {
    if (!isLoadingConfig && !selectedCategoryType) {
      if (availableCategories.length === 0) {
        setNotAvailableSchedule(true);
      } else if (availableCategories.length === 1) {
        setSessionCategoryType(availableCategories[0].categoryType);
        setCategoryType(availableCategories[0].categoryType);
        changeScheduler(availableCategories[0]);
      } else {
        if (deliveryData.isDelivery) {
          setSessionCategoryType(availableCategories[0].categoryType);
          setCategoryType(availableCategories[0].categoryType);
          changeScheduler(availableCategories[0]);
        }
        setOpenCategoryDrawer(true);
      }
    }

    if (!isLoadingConfig && selectedCategoryType) {
      const categoryConfig = availableCategories.find((ac) => ac.categoryType === selectedCategoryType);

      if (!categoryConfig) {
        setNotAvailableSchedule(true);
        return;
      }

      const {
        fastSchedule: { from },
      } = categoryConfig;
      const minTime = config.superFast.minTime / MIN_MS;
      const minsFrom = getMinutesOfDay(from);
      const timePrefix = minsFrom >= 720 ? 'pm' : 'am';
      const shceduleDisclaimer = getCurrentDayMinutes() + minTime <= minsFrom ? `${from}${timePrefix}` : null;

      if (shceduleDisclaimer && !acceptScheduleDisclaimer !== 'accepted') {
        showAlert({
          title: `Tu orden estará lista desde las ${shceduleDisclaimer}`,
          type: 'custom',
          className: 'text-white bg-baco-yellow-dark !p-2',
          classes: { title: '!text-xs !font-normal', close: 'h-4 w-4' },
          onClose: handleAcceptScheduleDisclaimer,
          customProps: { icon: <InfoIcon className="h-4 w-4" />, top: 60 },
        });
      }
    }
  }, [isLoadingConfig, availableCategoriesStr, selectedCategoryType]);

  const renderCategoryButton = (categoryConfig) => {
    const {
      categoryType,
      fastSchedule: { from },
    } = categoryConfig;
    const currTime = getCurrentDayMinutes();
    const fromTime = getMinutesOfDay(from);
    const minTime = config.superFast.minTime / MIN_MS;
    const isOnlySchedule = currTime + minTime < fromTime;

    return (
      <Button
        key={categoryType}
        onClick={() => handleCategoryType(categoryConfig)}
        className="!p-4 mb-4 bg-white"
        color="outline"
        type="button"
        fullWidth
      >
        <div className="w-full flex flex-col">
          <h3 className="font-bold text-base text-baco-primary text-left">
            {`${isOnlySchedule ? 'Programar' : 'Ordenar'} ${categoryType}`}
          </h3>
          <p className="text-sm mt-2 flex gap-x-2 items-center">
            <ScheduleIcon className="w-4 h-4" />
            <span>
              {isOnlySchedule
                ? `Entrega despues de ${from} ${fromTime < 12 * 60 ? 'am' : 'pm'}`
                : `Entrega de ${minTime} a ${config.superFast.maxTime / MIN_MS} minutos`}
            </span>
          </p>
        </div>
      </Button>
    );
  };

  const valueContext = useMemo(
    () => ({
      selectedCategoryType,
      sessionCategoryType,
      availableCategories,
      notAvailableSchedule,
      timers,
      handleCategoryType,
    }),
    [selectedCategoryType, sessionCategoryType, availableCategories, notAvailableSchedule, timers, handleCategoryType],
  );

  return (
    <Context.Provider value={valueContext}>
      {children}
      <Drawer
        open={openCategoryDrawer}
        direction="bottom"
        classes={{
          drawer: 'bg-gray-50',
          backdrop: 'z-20',
          content: 'h-full w-full max-w-4xl mx-auto',
        }}
      >
        <div>
          <h2 className="font-bold text-xl">¿Que deseas realizar?</h2>
          <p className="text-base mt-3 mb-4">Quieres ordenar tu desayuno o programar tu almuerzo.</p>
          {availableCategories.map((categoryConfig) => renderCategoryButton(categoryConfig))}
        </div>
      </Drawer>
    </Context.Provider>
  );
}

SchedulerContext.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useScheduler = () => useContext(Context);
export default SchedulerContext;
