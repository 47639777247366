import { motion, MotionConfig } from 'framer-motion';
import { Fragment, useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import CommonHeader from 'src/components/CommonHeader';
import ProductDescription from 'src/components/Product/components/ProductDescription';
import ProductHeader from 'src/components/Product/components/ProductHeader';
import ProductImage from 'src/components/Product/components/ProductImage';
import ProductPrice from 'src/components/Product/components/ProductPrice';
import mapLegacyStore from 'src/helpers/migrationRedirect';
import useScrollHandler from 'src/hooks/useScrollHandler';
import FormInputPanel from '../components/FormInputPanel';
import ModifierGroup from '../components/ModifierGroup';
import Skeleton from '../components/Skeleton';
import { useShoppingCart } from '../hooks/actions/useShoppingCart';
import { useStore } from '../hooks/actions/useStore';
import { useProductDetail } from '../hooks/services/useProductDetail';
import { useAmplitude } from '../hooks/useAmplitude';
import {
  addDisablesToModifiers, getProductPrice, hasMinOptionsRequired, mixProductWithForm,
} from '../utils/product';
import AddToCartButton from './components/AddToCartButton';

import '../styles/Product/index.css';

function ProductDetailSkeleton() {
  return (
    <div className="flex flex-col p-4 col-span-4 lg:p-8">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-auto">
        <Skeleton className="max-w-[500px] w-full aspect-square lg:ml-auto" />

        <div className="space-y-4 h-full max-w-[500px] w-full lg:mr-auto">
          <Skeleton className="h-16" />
          <Skeleton className="h-8" />

          <div className="space-y-8 !mt-16">
            <Skeleton className="h-12 w-1/2" />
            <Skeleton className="h-8 w-2/3" />
          </div>

          <div className="space-y-4">
            <Skeleton className="h-40" />
          </div>

          <Skeleton className="h-12" />
        </div>
      </div>
    </div>
  );
}

function ProductDetail() {
  useScrollHandler();
  const { selectedStore } = useStore();
  const { addProduct } = useShoppingCart();
  const [addedProduct, setAddedProduct] = useState(false);
  const navigate = useNavigate();
  const methods = useForm({
    defaultValues: {
      modifierGroups: [],
    },
  });
  const modifierGroupsForm = useWatch({
    control: methods.control,
    name: 'modifierGroups',
  });
  const { storeID: legacyID, productID } = useParams();
  const storeID = mapLegacyStore(legacyID);
  const { isLoading, productDetail } = useProductDetail({ storeID, productID });
  const modifierGroupsData = JSON.stringify(modifierGroupsForm);
  useEffect(() => {
    if (addedProduct) {
      setAddedProduct(false);
    }
  }, [modifierGroupsData]);

  const { trackAddItemToCart } = useAmplitude().useCartPageEvents();

  const handleBack = () => {
    const storePath = `/store/${legacyID}`;
    const savedPosition = sessionStorage.getItem(`scroll-${storePath}`);

    navigate(storePath, {
      state: {
        isBack: true,
        scrollPosition: savedPosition,
      },
    });
  };

  const handleCart = () => navigate(`/store/${legacyID}/cart`);

  const handleAddProduct = () => {
    trackAddItemToCart(productID);
    handleBack();
  };

  // Obtener el precio total del producto
  const calculatedModifierGroups = mixProductWithForm(productDetail, { modifierGroups: modifierGroupsForm });
  productDetail.total = getProductPrice(calculatedModifierGroups, productDetail);

  // Obtener los requisitos minimos para pedir
  productDetail.modifierGroups = addDisablesToModifiers(productDetail, modifierGroupsForm);
  const minOptionsRequired = hasMinOptionsRequired(productDetail);

  const onSubmit = (data) => {
    const modifierGroups = mixProductWithForm(productDetail, data);

    if (!addedProduct) {
      setAddedProduct(true);
      addProduct({
        ...productDetail,
        imageUrl: productDetail.shortcutImage,
        modifierGroups,
        observations: data.observations,
      });
    }
  };

  const textareaId = `observations-${productDetail.id}`;

  return (
    <MotionConfig transition={{ duration: 0.25 }}>
      <CommonHeader
        currentLocation={selectedStore}
        onGoToStores={handleBack}
        onGoToCart={handleCart}
        isLoading={isLoading}
        menu={[]}
        onSearch={() => {}}
        endComponent={null}
      />
      <motion.main
        className="product__wrapper"
        initial={{ left: '100%' }}
        animate={{ left: 0 }}
        exit={{ left: '100%' }}
      >

        {isLoading ? (
          <ProductDetailSkeleton />
        ) : (
          <section className="product__content">
            <ProductImage imageUrl={productDetail.shortcutImage} name={productDetail.name} />
            <div className="product__info">
              <div className="product__details">
                <ProductHeader
                  isTitle
                  name={productDetail.name}
                  tags={productDetail.tags}
                  discountedPrice={productDetail.discountedPrice}
                  price={productDetail.price}
                />
                <ProductDescription description={productDetail.description} />

                <ProductPrice
                  price={productDetail.price}
                  discountedPrice={productDetail.discountedPrice}
                  tags={productDetail.tags}
                />
              </div>
            </div>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)} className="product__form" id="product-detail-form">
                {!productDetail.isAvailable && <div className="">Producto No Disponible</div>}

                {productDetail.modifierGroups?.map((modifierGroup, index) => (
                  <ModifierGroup
                    className=""
                    index={index}
                    key={modifierGroup.id}
                    name={modifierGroup.name}
                    modifiers={modifierGroup.modifiers}
                    selectType={modifierGroup.selectType}
                    minAllowed={modifierGroup.minAllowed}
                    maxAllowed={modifierGroup.maxAllowed}
                    disables={modifierGroup.disables}
                    disabled={!productDetail.isAvailable}
                  />
                ))}
                <FormInputPanel
                  id="observations"
                  panelElements={[
                    {
                      label: (
                        <Fragment key={productDetail.id}>
                          <p>¿Algún comentario?</p>
                          <span>Déjanos saber algún otro requerimiento</span>
                        </Fragment>
                      ),
                      id: textareaId,
                      type: 'textarea',
                      name: 'observations',
                      placeholder: 'Ej. El picante por separado...',
                      rows: 3,
                      resizable: false,
                      fullWidth: true,
                      classes: ['product__observations'],
                    },
                  ]}
                />
              </form>
            </FormProvider>
          </section>
        )}
        <div className="product__footer" hidden={isLoading}>
          <AddToCartButton
            form="product-detail-form"
            product={productDetail}
            minRequirements={minOptionsRequired}
            startAnimation={addedProduct}
            onAnimationEnd={handleAddProduct}
          />
        </div>
        <div className="product__footer__img" hidden={isLoading}>
          <ProductImage imageUrl={productDetail.shortcutImage} name={productDetail.name} />
          <AddToCartButton
            form="product-detail-form"
            product={productDetail}
            minRequirements={minOptionsRequired}
            startAnimation={addedProduct}
            onAnimationEnd={handleAddProduct}
          />
        </div>
      </motion.main>
    </MotionConfig>
  );
}

ProductDetail.propTypes = {};

export default ProductDetail;
