import PropTypes from 'prop-types';
import AddressDrawer from './AddressDrawer';
import LocationIcon from './icons/LocationIcon';
import StoreIcon from './icons/StoreIcon';

function LocationInfo({
  isDelivery, storeName, address, onClick, isStore, openAddressDrawer, setOpenAddressDrawer, token, navigate,
}) {
  return (
    <>
      <button className="cart__location__wrapper" onClick={onClick} type="button" data-is-store={isStore}>
        <span className="cart__location__title">{isDelivery ? 'Dirección de envío' : 'Recoger en'}</span>
        {isDelivery ? <LocationIcon /> : <StoreIcon />}
        <span className="cart__location__address truncate">{isDelivery ? address : storeName}</span>
      </button>

      {isDelivery && (
        <AddressDrawer
          open={openAddressDrawer}
          onClose={() => setOpenAddressDrawer(false)}
          token={token}
          navigate={navigate}
        />
      )}
    </>
  );
}

export default LocationInfo;

LocationInfo.propTypes = {
  isDelivery: PropTypes.bool,
  storeName: PropTypes.string,
  address: PropTypes.string,
  onClick: PropTypes.func,
  isStore: PropTypes.bool,
  openAddressDrawer: PropTypes.bool,
  setOpenAddressDrawer: PropTypes.func,
  token: PropTypes.string,
  navigate: PropTypes.func,
};

LocationInfo.defaultProps = {
  isDelivery: false,
  storeName: '',
  address: '',
  onClick: () => null,
  isStore: false,
  openAddressDrawer: false,
  setOpenAddressDrawer: () => null,
  token: '',
  navigate: () => null,
};
