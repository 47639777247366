import PropTypes from 'prop-types';
import ArrowBack from './icons/ArrowBack';

function BackButton({ onClick, title }) {
  return (
    <button type="button" onClick={onClick} className="header__back-button" title={title} aria-label={title}>
      <ArrowBack alt="bacu-arrow-left" />
    </button>
  );
}

export default BackButton;

BackButton.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
};

BackButton.defaultProps = {
  onClick: () => null,
  title: 'Volver',
};
