import PropTypes from 'prop-types';
import { useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';
import Button from '../../../components/Button';
import FormInputPanel from '../../../components/FormInputPanel';
import {
  CloseIcon, EditIcon, ScheduleIcon, DoneIcon,
} from '../../../components/icons';

import StorePicked from '../../../components/StorePicked';
import config from '../../../config';
import { useShoppingCart } from '../../../hooks/actions/useShoppingCart';
import { useYupValidationResolver } from '../../../hooks/useYupValidatorResolver';
import { convertToTime } from '../../../utils/date';
import { useAmplitude } from '../../../hooks/useAmplitude';
import ContinueButton from './components/ContinueButton';

const pickInformation = yup.object({
  pick_method: yup.string().required('Debe elegir un método de recogida'),
});

const MIN_MS = 60000;

function getHour(date) {
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return convertToTime(hours, minutes);
}

function PickUp(props) {
  const [openSchedule, setOpenSchedule] = useState(false);
  const [isPickupEdited, setPickupEdited] = useState(false);
  const { changeOrderMethod } = useShoppingCart();
  const {
    store,
    isLoading,
    schedule,
    scheduler,
    minSchedule,
    maxSchedule,
    onSubmit,
    total,
    totalWithDiscount,
  } = props;

  const curr = new Date();
  const { fastSchedule: { from, to } } = scheduler;
  const [hoursFrom, minutesFrom] = from.split(':');
  const [hoursTo, minutesTo] = to.split(':');
  const suggestedFrom = new Date(curr.getFullYear(), curr.getMonth(), curr.getDate(), hoursFrom, minutesFrom, 0, 0);
  const suggestedTo = new Date(curr.getFullYear(), curr.getMonth(), curr.getDate(), hoursTo, minutesTo, 0, 0);
  const minFast = +curr + config.superFast.minTime;

  const superFastMin = config.superFast.minTime / MIN_MS;
  const superFastMax = config.superFast.maxTime / MIN_MS;
  const resolver = useYupValidationResolver(pickInformation);
  const methods = useForm({
    defaultValues: {
      schedule,
      pick_method: +suggestedFrom <= minFast && +suggestedTo > minFast
        ? config.pickupMethods.superFast
        : config.pickupMethods.scheduled,
    },
    resolver,
  });

  const sch = useWatch({
    control: methods.control,
    name: 'schedule',
  });

  const sDate = new Date(sch);

  const { trackBeginCheckout } = useAmplitude().useCheckoutPageEvents();

  const handleSubmit = (data) => {
    changeOrderMethod(data);
    trackBeginCheckout(data.pick_method);
    onSubmit(data);
  };
  const handleOpenSchedule = () => {
    setPickupEdited(true);
    setOpenSchedule(true);
  };
  const handleCloseSchedule = () => setOpenSchedule(false);

  const pickMethods = [];
  if (+suggestedFrom <= minFast && +suggestedTo > minFast) {
    pickMethods.push({
      value: config.pickupMethods.superFast,
      isChecked: <DoneIcon className="text-white bg-baco-primary rounded-full absolute -top-2 -right-2" />,
      label: (
        <div className="flex flex-col">
          <span className="text-sm">
            Súper rápido
          </span>
          <div className="flex items-center gap-2">
            <ScheduleIcon className="w-3.5 h-3.5" />
            <span className="text-xs">{`${superFastMin} - ${superFastMax} minutos`}</span>
          </div>
        </div>
      ),
    });
  }

  if (+maxSchedule >= +curr + config.scheduleTimeMin) {
    pickMethods.push({
      value: config.pickupMethods.scheduled,
      isChecked: <DoneIcon className="text-white bg-baco-primary rounded-full absolute -top-2 -right-2" />,
      onClick: handleOpenSchedule,
      label: (
        <div className="flex flex-col">
          <span className="text-sm">
            Programar recogida
          </span>
          <div className="flex items-center gap-1.5">
            <ScheduleIcon className="w-3.5 h-3.5" />
            <span className="text-xs">
              {`${!isPickupEdited ? 'Desde' : ''} ${getHour(sDate)}`}
            </span>
            {isPickupEdited && (
              <div className="flex">
                <EditIcon className="flex flex-col fill-baco-primary w-4 h-4 self-end" />
                <p className="flex flex-col underline text-xs self-center">Editar</p>
              </div>
            ) }
          </div>
        </div>
      ),
    });
  }

  return (
    <>
      <FormProvider {...methods}>
        <form className="flex flex-col h-full w-full" onSubmit={methods.handleSubmit(handleSubmit)} id="pick_method">
          <div className="h-full overflow-y-auto">
            <hr className="w-full h-[1px] border-none mt-4 mb-8 bg-gray-300" />
            <StorePicked {...store} hasTurbo />
            <hr className="w-full h-[1px] border-none my-8 bg-gray-300" />
            <h4 className="w-full text-base font-bold mb-4">
              ¿Cuándo quieres recoger tu pedido?
            </h4>
            <FormInputPanel
              id="pick_method"
              panelElements={[{
                type: 'radioButtonGroup',
                name: 'pick_method',
                options: pickMethods,
              }, {
                type: 'timePicker',
                label: (
                  <div className="flex flex-col">
                    <div className="flex justify-between items-end mb-3">
                      <h2 className="text-lg m-0 font-bold">Hora de recogida</h2>
                      <Button
                        type="button"
                        onClick={handleCloseSchedule}
                        color="outline"
                        size="md"
                      >
                        <CloseIcon />
                      </Button>
                    </div>
                    <p className="text-base m-0 mb-3">Escoge la hora de recogida de tu pedido</p>
                  </div>
                ),
                name: 'schedule',
                onClose: handleCloseSchedule,
                open: openSchedule,
                minTime: minSchedule,
                maxTime: maxSchedule,
                classes: ['mx-auto'],
              }]}
            />
          </div>
        </form>
      </FormProvider>
      <ContinueButton
        form="pick_method"
        total={total}
        totalWithDiscount={totalWithDiscount}
        isLoading={isLoading}
      />
    </>
  );
}

PickUp.propTypes = {
  store: PropTypes.shape({}),
  schedule: PropTypes.number,
  scheduler: PropTypes.any,
  minSchedule: PropTypes.number,
  maxSchedule: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
  total: PropTypes.number,
  totalWithDiscount: PropTypes.number,
};

PickUp.defaultProps = {
  store: {},
  scheduler: {},
  schedule: 0,
  minSchedule: 0,
  isLoading: false,
  onSubmit: () => null,
  total: 0,
  totalWithDiscount: 0,
};

export default PickUp;
