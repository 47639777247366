import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import EmptyState from 'src/components/icons/EmptyState';
import LocationInfo from 'src/components/LocationInfo';
import ProductDescription from 'src/components/Product/components/ProductDescription';
import ProductHeader from 'src/components/Product/components/ProductHeader';
import ProductImage from 'src/components/Product/components/ProductImage';
import ProductPrice from 'src/components/Product/components/ProductPrice';
import { useLogin } from 'src/contexts/Login.context';
import Button from '../../../components/Button';
import FormInputPanel from '../../../components/FormInputPanel';
import { DeleteIcon, EditIcon } from '../../../components/icons';
import IllustratedMessage from '../../../components/IllustratedMessage';
import { useShoppingCart } from '../../../hooks/actions/useShoppingCart';
import { useAmplitude } from '../../../hooks/useAmplitude';
import { getDescriptionFromModifiers } from '../../../utils/string';
import ContinueButton from './components/ContinueButton';

function Overview({ store, isLoading, onSubmit }) {
  const {
    items,
    discount: appliedDiscount,
    itemsNotAvailable,
    removeProduct,
    addDiscount,
    clearCart,
  } = useShoppingCart();
  const [openAddressDrawer, setOpenAddressDrawer] = useState(false);
  const { token } = useLogin();

  const navigate = useNavigate();
  const onGoToStores = () => navigate('/stores');

  const methods = useForm({
    defaultValues: { items, discount: appliedDiscount },
  });

  useEffect(() => {
    methods.reset({
      items,
    });
  }, [items]);

  const { items: products, discount } = useWatch({
    control: methods.control,
  });

  const { trackViewCart, trackConfirmCart } = useAmplitude().useCartPageEvents();

  useEffect(() => {
    const { productIds, totalQuantity, totalPrice } = products.reduce(
      (acc, currentProduct) => {
        acc.productIds.push(currentProduct.id);
        acc.totalQuantity += currentProduct.count;
        acc.totalPrice += currentProduct.price * currentProduct.count;
        return acc;
      },
      { productIds: [], totalQuantity: 0, totalPrice: 0 },
    );
    trackViewCart(productIds, totalQuantity, totalPrice);
  }, [products]);

  const total = products.reduce((sum, product) => sum + product.count * product.total, 0);
  let totalWithDiscount = 0;

  const handleBack = () => navigate(-1);

  const { type, value } = discount || {};

  if (discount) {
    totalWithDiscount = type === 'percentage' ? total * ((100 - value) / 100) : total - value;
  }

  const handleRemoveItem = (index, item) => removeProduct(index, item);
  const handleSubmit = (data) => {
    trackConfirmCart();
    if (data.discount) {
      addDiscount(data.discount);
    }

    onSubmit({
      ...data,
      total,
      totalWithDiscount,
    });
  };

  const getItemCard = (index, item, disabled) => {
    const description = getDescriptionFromModifiers(item.modifierGroups);

    return (
      <>
        <ProductImage imageUrl={item.imageUrl} name={item.name} />
        <button
          type="button"
          onClick={() => navigate(`/store/${store.storeId}/product/${item.id}`)}
          className="cart__item__card__edit"
          title="Editar producto"
        >
          <EditIcon />
        </button>
        <ProductHeader name={item.name} />
        <ProductDescription description={description ?? item.description} />
        <ProductPrice price={item.price} discountedPrice={item.discountedPrice} total={item.total} />

        <button
          type="button"
          onClick={() => handleRemoveItem(index, item)}
          className="cart__item__card__delete"
          title="Eliminar producto"
        >
          <DeleteIcon className="" />
        </button>
        {disabled && (
          <div className="cart__overview-item-not-available">
            <p className="text-sm font-bold text-baco-error-light mb-2">
              ¡Lo sentimos! Este producto ya no está disponible.
            </p>
          </div>
        )}
      </>
    );
  };

  if (items.length === 0) {
    return (
      <>
        <IllustratedMessage
          className="cart__overview-empty"
          title=""
          description={
            'Aún no tienes productos en tu carrito. Explora nuestro menú y agrega tus platos '
            + 'favoritos para comenzar tu pedido.'
          }
          illustration={<EmptyState />}
        />
        <Button onClick={handleBack} className="m-0" size="lg" fullWidth>
          Ver menú
        </Button>
      </>
    );
  }

  return (
    <>
      <LocationInfo
        isDelivery={store.isDelivery}
        storeName={store?.name}
        address={store.address}
        onClick={store.isDelivery ? () => setOpenAddressDrawer(true) : onGoToStores}
        openAddressDrawer={openAddressDrawer}
        setOpenAddressDrawer={setOpenAddressDrawer}
        token={token}
        navigate={navigate}
      />

      <button
        type="button"
        onClick={() => {
          clearCart();
          handleBack();
        }}
        className="cart__clear"
      >
        Eliminar todos los productos
      </button>

      <FormProvider {...methods}>
        <form className="cart__overview" onSubmit={methods.handleSubmit(handleSubmit)} id="cart-overview-form">
          <FormInputPanel
            id="cart-overview"
            panelElements={items.map((item, index) => ({
              type: 'counter',
              label: getItemCard(index, item),
              direction: 'row',
              name: `items.${index}.count`,
              min: 1,
              fullWidth: true,
              classes: [''],
              size: 'lg',
            }))}
          />
          {itemsNotAvailable.map((item) => (
            <div key={item.id}>{getItemCard(item, true)}</div>
          ))}
          <FormInputPanel
            id="cart-discount"
            panelElements={[
              {
                type: 'discount',
                label: !discount ? (
                  <span className="text-base font-bold text-baco-carbon">¿Tienes un código de descuento?</span>
                ) : (
                  <div className="flex flex-col items-center justify-center">
                    <h2 className="text-baco-primary text-base font-bold text-center">¡Felicitaciones!</h2>
                    <p className="text-baco-primary text-base font-bold text-center">
                      Aplicaste un código de descuento
                    </p>
                  </div>
                ),
                placeholder: 'Ingresa el código',
                name: 'discount',
                fullWidth: true,
                classes: ['gap-4'],
                storeID: store.storeId,
              },
            ]}
          />
        </form>
      </FormProvider>
      <ContinueButton
        title="Pagar pedido"
        total={total}
        totalWithDiscount={totalWithDiscount}
        handleBack={handleBack}
        isLoading={isLoading}
        form="cart-overview-form"
      />
    </>
  );
}
Overview.propTypes = {
  store: PropTypes.any,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
};

Overview.defaultProps = {
  store: {},
  onSubmit: () => null,
  isLoading: false,
};

export default Overview;
