import { motion, useAnimation } from 'framer-motion';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const DIRECTION_PREV = {
  left: {
    from: '0%',
    to: '-100%',
  },
  right: {
    from: '0%',
    to: '100%',
  },
};

const DIRECTION_CURR = {
  left: {
    from: '100%',
    to: '0%',
  },
  right: {
    from: '-100%',
    to: '0%',
  },
};

function AnimatedStepper({
  direction, previous, current, duration, className,
}) {
  const controlPrev = useAnimation();
  const controlCurr = useAnimation();
  const [prevNode, setPrevNode] = useState();
  const [currNode, setCurrNode] = useState();

  useEffect(() => {
    setPrevNode(previous);
    setCurrNode(current);

    if (previous) {
      controlPrev.start({
        left: [DIRECTION_PREV[direction].from, DIRECTION_PREV[direction].to],
        transition: {
          duration,
          times: [0, 0.25],
        },
      });
      controlCurr.start({
        left: [DIRECTION_CURR[direction].from, DIRECTION_CURR[direction].to],
        transition: {
          duration,
          times: [0, 0.25],
        },
      });
    }
  }, [previous, current, duration]);

  return (
    <>
      {prevNode && (
        <motion.div
          key={`m-${(prevNode?.key ?? '') + crypto.randomUUID()}`}
          className="absolute top-0 left-full w-full h-full cart__previous-step"
          animate={controlPrev}
        >
          {prevNode}
        </motion.div>
      )}

      <motion.div
        key={`m-${(currNode?.key ?? '') + crypto.randomUUID()}`}
        className={`absolute top-0 left-0 w-full h-full ${className}`}
        animate={controlCurr}
      >
        {currNode}
      </motion.div>
    </>
  );
}

AnimatedStepper.propTypes = {
  direction: PropTypes.oneOf(['left', 'right']),
  previous: PropTypes.node,
  current: PropTypes.node.isRequired,
  duration: PropTypes.number,
  className: PropTypes.string,
};

AnimatedStepper.defaultProps = {
  direction: 'left',
  previous: null,
  duration: 0.25,
  className: 'cart__current-step',
};

export default AnimatedStepper;
