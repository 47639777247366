import PropTypes from 'prop-types';

function Search({ className, size }) {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        // eslint-disable-next-line max-len
        d="M17.5 17.5L13.8808 13.8808M13.8808 13.8808C14.4999 13.2617 14.991 12.5268 15.326 11.7179C15.6611 10.9091 15.8335 10.0422 15.8335 9.16666C15.8335 8.29115 15.6611 7.42422 15.326 6.61537C14.991 5.80651 14.4999 5.07156 13.8808 4.45249C13.2618 3.83342 12.5268 3.34234 11.718 3.0073C10.9091 2.67226 10.0422 2.49982 9.16667 2.49982C8.29116 2.49982 7.42424 2.67226 6.61538 3.0073C5.80652 3.34234 5.07157 3.83342 4.4525 4.45249C3.20223 5.70276 2.49983 7.3985 2.49983 9.16666C2.49983 10.9348 3.20223 12.6305 4.4525 13.8808C5.70277 15.1311 7.39851 15.8335 9.16667 15.8335C10.9348 15.8335 12.6306 15.1311 13.8808 13.8808Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

Search.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
};

Search.defaultProps = {
  className: '',
  size: 20,
};

export default Search;
