import PropTypes from 'prop-types';
import LocationIcon from '../icons/LocationIcon';

function HeaderLocation({
  title, onClick,
}) {
  return (
    <button className="header__location-store" onClick={onClick} type="button">
      <LocationIcon />
      <span className="header__location-text">{title}</span>
    </button>
  );
}

export default HeaderLocation;

HeaderLocation.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
};

HeaderLocation.defaultProps = {
  title: '',
  onClick: () => null,
};
