import PropTypes from 'prop-types';
import Button from './Button/index';
import Drawer from './Drawer/index';
import { CloseIcon } from './icons/index';

function AddressDrawer({
  open, onClose, token, navigate,
}) {
  return (
    <Drawer
      open={open}
      direction="bottom"
      keyboard="true"
      classes={{
        drawer: 'bg-gray-50 max-w-md !inset-x-0 !m-auto',
        backdrop: 'z-20',
        content: 'h-full w-full max-w-4xl mx-auto px-2',
      }}
    >
      <div className="flex flex-col w-full checked:bg-baco-primary">
        <nav className="flex justify-end py-0">
          <button type="button" onClick={onClose}>
            <CloseIcon className="fill-baco-primary w-7 scale-110" />
          </button>
        </nav>
        <div className="font-bold text-xl py-4">¿Quieres cambiar la dirección?</div>
        <div className="text-md text-left pb-10">
          Al cambiar la dirección, tendrás que seleccionar tus productos nuevamente.
        </div>
        <Button type="submit" size="lg" fullWidth className="!text-base font-bold" onClick={onClose}>
          <span className="mr-2">Mantener dirección actual</span>
        </Button>
        <button
          type="button"
          className="text-xs underline my-6"
          onClick={() => {
            if (token) {
              navigate('/MyAddress');
            } else {
              navigate('/AddressInput');
            }
          }}
        >
          Cambiar dirección de entrega
        </button>
      </div>
    </Drawer>
  );
}

export default AddressDrawer;

AddressDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  token: PropTypes.string,
  navigate: PropTypes.func,
};

AddressDrawer.defaultProps = {
  open: false,
  onClose: () => null,
  token: '',
  navigate: () => null,
};
